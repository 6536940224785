.hwrap {
  overflow: hidden;
  background: transparent;
  border-bottom: 0.1px solid rgba(255, 255, 255, 0.3);
}

.hmove {
  display: flex;
}

.hitem {
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  color: #ffffff;
  margin-right: 10em;
}

@keyframes tickerh {
  0% {
    transform: translate3d(90%, 0, 0);
  }
  100% {
    transform: translate3d(-40%, 0, 0);
  }
}

.hmove {
  animation: tickerh linear 35s infinite;
}

.hmove:hover {
  animation-play-state: paused;
}
