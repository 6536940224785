.link {
  flex: 1;
  padding: 1em;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 140px;
  max-width: 140px;
}

.link:hover {
  text-decoration: none;
}

.link > img {
  opacity: 0.8;
}

.link:hover > img {
  transition: all 400ms;
  transform: scale(115%);
  opacity: 1;
}

.link:hover > p {
  color: #099f84;
}

.main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  margin-top: 2em;
}

.assetContainer {
  display: flex;
  flex-direction: row;
  flex-basis: 350px;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2em;
}

.img {
  width: 100%;
  object-fit: contain;
}

.selectorDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-top: 1em;
  max-width: 500px;
  flex-wrap: wrap;
  margin: 1em auto;
}

.typeButtonDiv {
  display: flex;
  flex-direction: row;
  padding: 8px 12px 8px 8px;
  min-width: 240px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.typeButtonDiv:hover {
  cursor: pointer;
  color: white;
  background: rgba(255, 255, 255, 0.04);
}

.assetImgDiv {
  display: flex;
  flex: 1;
}

.assetImg {
  width: 1.5em;
  object-fit: contain;
  margin-left: 0.4em;
}

.assetName {
  display: flex;
  align-items: center;
  text-align: left;
  flex: 2;
}
